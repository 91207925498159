import {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip,
  LazySVGSprite,
  Datepicker
} from "@wko-inhouse/wko-ds/dist/js/wko-ds-bootstrap.bundle";

export {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip,
  LazySVGSprite,
  Datepicker
};

window.addEventListener("load", function () {
  const accordionButton = document.getElementById("btn-nutzungsbedingungen");
  const accordionCollapse = document.getElementById("collapse-nutzung");
  document.getElementById("jump-link").addEventListener("click", function () {
    accordionButton.classList.remove("collapsed");
    accordionCollapse.classList.add("show");
  });
});
